import {
  FullPost, GeoData, Geoloc,
  Id,
  MediaStatus,
  Post,
  PostComment,
  PostLike,
  PostSuperLike, SavedChallenge
} from 'bee';
import firebase from 'firebase/app';
import { bAnalytics, Events } from '../utils/analytics';
import FirestoreService, {
  converterWithId,
  unwrapQuery,
  unwrapSnapshot
} from './FirestoreService';

export interface CreatePostData {
  postId: string;
  userId: string;
  description: string;
  location: string;
  mediaUrl: string | null;
  mediaType: string | null;
  mediaStatus: MediaStatus | null;
  locale: string;
  geodata: GeoData,
  _geoloc: Geoloc,
  isChallenge?: boolean;
  rootPost?: string;
  parentPost?: string;
  cause?: string;
}

const getUserInfo = () => ({
  userId: firebase.auth().currentUser!.uid
});

export default class PostsService extends FirestoreService<Post> {
  static instance = new PostsService();

  constructor() {
    super('posts');
  }

  async create({ postId, ...data }: CreatePostData): Promise<Post & Id> {
    const post: FullPost = {
      vetted: false,
      postedTimestamp: new Date().getTime(),
      reportedCount: 0,
      ...data // moved because we added post editing.
    };

    try{
      await this.set(postId, post);
    }
    catch (e) {
      console.error('@@ uh oh', e);
    }

    return {
      id: postId,
      ...post
    };
  }

  async getMostSuperLikedPosts({
    limit
  }: {
    limit: number;
    lastVisible?: number;
    userId?: string;
  }) {
    return unwrapQuery(
      this.collection().orderBy('superLikeCount', 'desc').limit(limit).get()
    );
  }

  async getMostRecentPosts({
    limit,
    startAfter,
    specificUserId
  }: {
    limit: number;
    startAfter?: number;
    specificUserId?: string;
  }) {
    let query = this.collection()
      .orderBy('postedTimestamp', 'desc')
      .limit(limit);

    if (specificUserId) {
      query = query.where('userId', '==', specificUserId);
    }

    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    return unwrapQuery(query.get());
  }

  incomingPosts$(
    { since, specificUserId }: { since: number; specificUserId?: string },
    callback: (result: (Post & Id)[]) => void
  ) {
    let query = this.collection()
      .orderBy('postedTimestamp', 'desc')
      .endBefore(since);

    if (specificUserId) {
      query = query.where('userId', '==', specificUserId);
    }

    return query.onSnapshot((sn) => callback(unwrapSnapshot(sn)));
  }

  commentsQuery(postId: string) {
    return this.commentsCollection(postId).orderBy('commentTimestamp');
  }

  descendantsQuery(
    postId: string,
    limit: number = 3
  ) {
    return this.collection()
      .where('rootPost', '==', postId)
      .orderBy('postedTimestamp', 'desc')
      .limit(limit);
  }

  isChallengeCompleted$(
    postId: string,
    callback: (completed: boolean) => void
  ) {
    const uid = firebase.auth().currentUser!.uid;

    return this
      .collection()
      .where('rootPost', '==', postId)
      .where('userId', '==', uid)
      .limit(1)
      .onSnapshot((sn) => {
        callback(!sn.empty);
      });
  }

  async addLike(postId: string) {
    await this.likesCollection(postId).add({
      id: '',
      ...getUserInfo()
    });
    bAnalytics.track(Events.Liked, { postId });
  }

  async addSuperLike(postId: string) {
    await this.superLikesCollection(postId).add({
      id: '',
      ...getUserInfo()
    });
    bAnalytics.track(Events.SuperLiked, { postId });
  }

  async addComment(postId: string, comment: string): Promise<PostComment & Id> {
    const postComment: PostComment = {
      ...getUserInfo(),
      comment: comment,
      commentTimestamp: new Date().getTime()
    };

    const { id } = await this.commentsCollection(postId).add({
      id: '',
      comment: comment,
      commentTimestamp: new Date().getTime(),
      ...getUserInfo()
    });

    bAnalytics.track(Events.CreatedComment, { comment });

    return { id, ...postComment };
  }

  async reportPost(id: string) {
    return PostsService.instance.doc(id).update({
      reportedCount: firebase.firestore.FieldValue.increment(1)
    });
  }

  async hasLiked(postId: string): Promise<boolean> {
    const uid = firebase.auth().currentUser?.uid
    if (!uid) return false
    
    const likes = await this.likesCollection(postId)
      .where('userId', '==', uid)
      .limit(1)
      .get()
      
    return !likes.empty
  }

  mediaStatus$(
    postId: string,
    callback: (result?: {
      mediaUrl: string | null;
      mediaStatus: MediaStatus;
    }) => void
  ) {
    return this.doc(postId).onSnapshot((sn) => {
      const post = sn.data() as Post | undefined;

      if (!post?.mediaStatus) {
        callback(undefined);
        return;
      }

      callback({
        mediaUrl: post.mediaUrl,
        mediaStatus: post.mediaStatus
      });
    });
  }

  descendants$(
    postId: string,
    callback: (posts: (Post & Id)[]) => void,
    limit: number = 4
  ) {
    return this
      .descendantsQuery(postId, limit)
      .onSnapshot((sn) => {
        const docs = sn.docs.map(doc => doc.data());
        callback(docs);
      });
  }

  savedChallengesCollection(id: string) {
    return firebase
      .firestore()
      .collection('profiles')
      .doc(id)
      .collection('savedChallenges');
  }

  saveChallenge(postId: string) {
    const uid = firebase.auth().currentUser!.uid;
    return this
      .savedChallengesCollection(uid)
      .doc(postId)
      .set({
        savedAt: new Date().getTime()
      });
  }

  unsaveChallenge(postId: string) {
    const uid = firebase.auth().currentUser!.uid;
    try {
      return this
        .savedChallengesCollection(uid)
        .doc(postId)
        .delete();
    } catch (e) {
      console.warn(e);
      return;
    }
  }

  isChallengeSaved$(
    postId: string,
    callback: (saved: boolean) => void
  ) {
    const uid = firebase.auth().currentUser?.uid;
    if (!uid) {
      callback(false);
      return () => null;
    }
    return this
      .savedChallengesCollection(uid)
      .doc(postId)
      .onSnapshot(sn => {
        callback(sn.exists);
      });
  }

  async getSavedChallenges(
    limit: number = 20
  ) {
    const uid = firebase.auth().currentUser!.uid;
    const challenges = await this
      .savedChallengesCollection(uid)
      .limit(limit)
      .withConverter(converterWithId<SavedChallenge>())
      .get();
    return challenges.docs.map(doc => doc.data());
  }

  savedChallenges$(
    limit: number = 20,
    callback: (saved: (SavedChallenge & Id)[]) => void
  ) {
    const uid = firebase.auth().currentUser!.uid;
    return this
      .savedChallengesCollection(uid)
      .orderBy('savedAt', 'desc')
      .limit(limit)
      .withConverter(converterWithId<SavedChallenge>())
      .onSnapshot(sn => {
        callback(sn.docs.map(doc => doc.data()));
      });
  }

  private likesCollection(postId: string) {
    return this.doc(postId)
      .collection('likes')
      .withConverter(converterWithId<PostLike>());
  }

  private superLikesCollection(postId: string) {
    return this.doc(postId)
      .collection('superLikes')
      .withConverter(converterWithId<PostSuperLike>());
  }

  private commentsCollection(postId: string) {
    return this.doc(postId)
      .collection('comments')
      .withConverter(converterWithId<PostComment>());
  }
}
