import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FavoriteRounded,
  Share,
  StarRounded,
  OpenInNew,
} from '@material-ui/icons';
import { Id, Post } from 'bee';
import React, { memo, useCallback, useState } from 'react';
import useLocalizedContent from '../../../../hooks/useLocalizedContent';
import useUser from '../../../../hooks/useUser';
import PostsService from '../../../../services/PostsService';
import { actions as postActions } from '../../../../slices/posts';
import { useAppDispatch } from '../../../../store';
import { Link, generatePath } from 'react-router-dom';
import { ROUTES } from '../../../BRouter';


import useSWR from 'swr'

function useLikeStatus(postId: string) {
  const { data: hasLiked, isLoading, mutate } = useSWR(
    postId ? ['postLike', postId] : null,
    async () => PostsService.instance.hasLiked(postId),
  )

  return { hasLiked, isLoading, refetch: mutate }
}

interface Props {
  post: Post & Id;
  onAddLike: () => void;
  onAddSuperLike: () => void;
  showError: () => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 6,
    '&.Mui-disabled': {
      opacity: theme.palette.action.disabledOpacity,
    },
    '& svg': {
      width: '1.1em',
      height: '1.1em',
    },
  },
  likeButton: {
    '& svg': {
      fill: theme.palette.like,
    },
  },
  superLikeButton: {
    padding: 3,
    '& svg': {
      width: '1.3em',
      height: '1.3em',
      fill: theme.palette.superLike,
    },
  },
  shareButton: {
    marginLeft: -1,
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  openButton: {
    marginLeft: -1,
    '& svg': {
      fill: theme.palette.grey.A700,
    },
  },
}));

const PostActions: React.FC<Props> = memo(
  ({ post, onAddLike, onAddSuperLike, showError }) => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const content = useLocalizedContent('my_posts');
    const isLoggedIn = !!useUser();

    const [likeLoading, setLikeLoading] = useState(false);
    const [superLikeLoading, setSuperLikeLoading] = useState(false);
    const { hasLiked, isLoading: hasLikedLoading, refetch: refetchHasLiked } = useLikeStatus(post.id)

    const onLikeClicked = useCallback(async () => {
      setLikeLoading(true);

      try {
        if (!!hasLiked && !hasLikedLoading) {
          console.log('@@ already liked');
          return;
        }
        await PostsService.instance.addLike(post.id);
        await refetchHasLiked();
        onAddLike();
      } catch (e) {
        showError();
      } finally {
        setLikeLoading(false);
      }
    }, [hasLiked, hasLikedLoading, post.id, refetchHasLiked, onAddLike, showError]);

    const onSuperLikeClicked = useCallback(async () => {
      setSuperLikeLoading(true);

      try {
        await PostsService.instance.addSuperLike(post.id);
        onAddSuperLike();
      } catch (e) {
        showError();
      } finally {
        setSuperLikeLoading(false);
      }
    }, [post.id, onAddSuperLike, showError]);

    const onShareClicked = useCallback(() => {
      dispatch(postActions.setSharePost(post));
    }, [dispatch, post]);

    return (
      <>
        <IconButton
          classes={{ root: classes.button }}
          className={classes.likeButton}
          title={content.like}
          onClick={onLikeClicked}
          disabled={!isLoggedIn || likeLoading}
        >
          <FavoriteRounded />
        </IconButton>
        <IconButton
          classes={{ root: classes.button }}
          className={classes.superLikeButton}
          title={content.super_like}
          onClick={onSuperLikeClicked}
          disabled={!isLoggedIn || superLikeLoading}
        >
          <StarRounded />
        </IconButton>
        <IconButton
          classes={{ root: classes.button }}
          className={classes.shareButton}
          title={content.share}
          onClick={onShareClicked}
        >
          <Share />
        </IconButton>
        <Link to={generatePath(ROUTES.singlePost, { id: post.id })}>
          <IconButton
            classes={{ root: classes.button }}
            className={classes.openButton}
            title={content.share}
          >
            <OpenInNew />
          </IconButton>
        </Link>
      </>
    );
  }
);

export default PostActions;
