import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Stars } from '@material-ui/icons';
import { Id, Post, PostComment } from 'bee';
import clsx from 'clsx';
import { produce } from 'immer';
import { LinkifyOptions } from 'linkifyjs/react';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import useChallengeCompleted from '../../../../hooks/useChallengeCompleted';
import useChallengeSaved from '../../../../hooks/useChallengeSaved';
import useI18nFormat from '../../../../hooks/useI18nFormat';
import useLocalizedContent, {
  usePluralize,
} from '../../../../hooks/useLocalizedContent';
import useProfile from '../../../../hooks/useProfile';
import useShowAcceptChallenge from '../../../../hooks/useShowAcceptChallenge';
import useShowLogin from '../../../../hooks/useShowLogin';
import useStartDonation from '../../../../hooks/useStartDonation';
import useUser from '../../../../hooks/useUser';
import PostsService from '../../../../services/PostsService';
import { actions as rootActions } from '../../../../slices/root';
import { useAppDispatch } from '../../../../store';
import { bAnalytics, Events } from '../../../../utils/analytics';
import { ROUTES } from '../../../BRouter';
import BProfileIcon from '../../../utils/BProfileIcon';
import BProfileLink from '../../../utils/BProfileLink';
import BUserAvatar from '../../../utils/BUserAvatar';
import BLinkify from './BLinkify';
import BPostCardListItem from './BPostCardListItem';
import BPostDescendants from './BPostDescendants';
import CommentForm from './CommentForm';
import CommentsList from './CommentsList';
import PostActions from './PostActions';
import PostMedia from './PostMedia';
import PostMoreMenu from './PostMoreMenu';

export interface BPostCardProps {
  post: Post & Id;
  comments: (PostComment & Id)[];
  descendants?: (Post & Id)[];
  standalone?: boolean;
  embedded?: boolean;
  disableEmbed?: boolean;
}

const SPECIAL_BORDER_WIDTH = 4;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    width: '100%',
  },
  postMeta: {
    textDecoration: 'none',
  },
  icon: {
    position: 'absolute',
    top: -2,
    left: -2,
    zIndex: 99,
    color: theme.palette.brand.main,
    width: 15,
    height: 15,
    background: 'white',
    borderRadius: 8,
  },
  profileLink: {
    position: 'relative',
  },
  fromBee: {
    border: `${SPECIAL_BORDER_WIDTH}px solid transparent`,
    background: theme.palette.background.paper,
    backgroundClip: 'padding-box',
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'visible',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: -1,
      margin: -SPECIAL_BORDER_WIDTH,
      borderRadius: 'inherit',
      background: theme.gradients.brandCircle,
    },
  },
  headerIcon: {
    fill: theme.palette.primary.dark,
  },
  link: {
    color: '#999999',
    fontSize: 16,
    marginBottom: -3,
  },
  actionBar: {
    display: 'flex',
    padding: theme.spacing(0.25, 1.5, 0.25, 0.75),
    alignItems: 'center',
  },
  headerContent: {
    overflow: 'hidden',
  },
  actions: {
    marginRight: 'auto',
  },
  section: {
    padding: theme.spacing(1, 1.5),
  },
  sectionBorder: {
    borderBottom: `1px solid ${theme.palette.divider96}`,
  },
  comment: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
  },
  '@keyframes ButtonAnim': {
    '0%': {
      backgroundPosition: '0% 0%',
    },
    '50%': {
      backgroundPosition: '100% 100%',
    },
  },
  donateButton: {
    backgroundColor: '#6d60fa',
    background: 'linear-gradient(317deg, #3eaf92, #4d3ea4)',
    backgroundSize: '400% 400%',
    color: 'white',
    animationName: '$ButtonAnim',
    animationDuration: '4s',
    animationTimingFunction: 'ease',
    animationIterationCount: 'infinite',
  },
}));

const BPostCard = forwardRef<HTMLDivElement, BPostCardProps>(
  (
    { post: _post, comments, descendants, standalone, embedded, disableEmbed },
    ref
  ) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const i18nFormat = useI18nFormat();
    const content = useLocalizedContent('my_posts');
    const pluralize = usePluralize(content);
    const isLoggedIn = !!useUser();
    const [livePost, setLivePost] = useState<Post & Id>();
    const post = livePost ?? _post;
    const likeCount = post.likeCount ?? 0;
    const superLikeCount = post.superLikeCount ?? 0;
    const profile = useProfile(post.userId);
    const { isChallenge } = post;
    const challengeCompleted = useChallengeCompleted(post.id, true);
    const challengeSaved = useChallengeSaved(post.id, true);


    const showLogin = useShowLogin();
    const showAcceptChallenge = useShowAcceptChallenge();
    const startDonation = useStartDonation({
      donationCause: post.cause,
      donationChallenge: post.isChallenge ? post.id : undefined,
    });

    const onAddLike = useCallback(
      () =>
        setLivePost((lp) =>
          produce(lp ?? _post, (d) => {
            d.likeCount = (d.likeCount ?? 0) + 1;
          })
        ),
      [_post]
    );

    const onAddSuperLike = useCallback(
      () =>
        setLivePost((lp) =>
          produce(lp ?? _post, (d) => {
            d.superLikeCount = (d.superLikeCount ?? 0) + 1;
          })
        ),
      [_post]
    );

    const showError = useCallback(
      () =>
        dispatch(
          rootActions.showInfo({
            text: 'Something went wrong',
            severity: 'error',
          })
        ),
      [dispatch]
    );

    const linkifyOptions: LinkifyOptions = useMemo(
      () => ({
        attributes: {
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            bAnalytics.track(Events.ClickedPostLink, {
              ...post,
              url: e.currentTarget.href,
            });
          },
        },
      }),
      [post]
    );

    const timeProps = useMemo(
      () =>
        post.postedTimestamp
          ? {
            title: i18nFormat.format(post.postedTimestamp),
            dateTime: new Date(post.postedTimestamp).toISOString(),
            children: i18nFormat.formatDistance(post.postedTimestamp),
          }
          : {
            title: '',
            dateTime: '',
            children: '',
          },
      [i18nFormat, post.postedTimestamp]
    );

    const actionSummary = useMemo(
      () =>
        [
          ...(likeCount ? [pluralize('like_count', likeCount)] : []),
          ...(superLikeCount
            ? [pluralize('super_like_count', superLikeCount)]
            : []),
        ].join(' · '),
      [likeCount, pluralize, superLikeCount]
    );

    return (
      <Card
        ref={ref}
        className={clsx(classes.root, post.fromBee && classes.fromBee)}
      >
        <CardHeader
          classes={{
            content: classes.headerContent,
          }}
          avatar={
            <BProfileLink userId={post.userId} className={classes.profileLink}>
              {isChallenge && <Stars className={classes.icon} />}
              <BUserAvatar userId={post.userId} />
            </BProfileLink>
          }
          action={
            <Box display="flex" alignItems="center">
              {profile && <BProfileIcon profile={profile} />}
              <PostMoreMenu post={post} />
            </Box>
          }
          title={
            <BProfileLink userId={post.userId}>
              {profile?.username}
            </BProfileLink>
          }
          titleTypographyProps={{
            noWrap: true,
          }}
          subheader={
            <>
              <Link
                to={
                  ROUTES.getInspired +
                  (post.geodata?.bounds
                    ? '?q=&locationLabel=' +
                    encodeURIComponent(post.location) +
                    '&bounds=' +
                    encodeURIComponent(JSON.stringify(post.geodata.bounds))
                    : post._geoloc
                      ? '?q=&locationLabel=' +
                      encodeURIComponent(post.location) +
                      '&coords=' +
                      encodeURIComponent(JSON.stringify(post._geoloc))
                      : `?q=${encodeURIComponent(post.location)}`)
                }
              >
                {post.location}
              </Link>{' '}
              {/* &middot;
              <time {...timeProps} /> */}
            </>
          }
        />
        <PostMedia post={post} />
        {!!post.description && (
          <div className={clsx(classes.section, classes.sectionBorder)}>
            <BLinkify options={linkifyOptions}>{post.description}</BLinkify>
          </div>
        )}
        <div className={clsx(classes.sectionBorder, classes.actionBar)}>
          <div className={classes.actions}>
            <PostActions
              post={post}
              onAddLike={onAddLike}
              onAddSuperLike={onAddSuperLike}
              showError={showError}
            />
          </div>
          <Link
            to={generatePath(ROUTES.singlePost, { id: post.id })}
            className={classes.postMeta}
          >
            <Typography variant="caption" color="textSecondary" align="right">
              {actionSummary}
              {!!comments.length && (
                <>
                  {actionSummary && <br />}
                  {pluralize('comment_count', comments.length)}
                </>
              )}
            </Typography>
          </Link>
        </div>
        {!!isChallenge && !post.rootPost && !!descendants && !embedded && (
          <BPostDescendants descendants={descendants} postId={post.id} />
        )}
        {!!comments.length && !embedded && (
          <CommentsList
            className={clsx(classes.section, classes.sectionBorder)}
            comments={comments}
            expandByDefault={standalone}
          />
        )}
        {isLoggedIn && !embedded && (
          <div
            className={clsx(
              classes.section,
              classes.comment,
              isChallenge && classes.sectionBorder
            )}
          >
            <CommentForm postId={post.id} />
          </div>
        )}
        {!!isChallenge && !post.rootPost && false && (
          <Grid
            container
            className={clsx(classes.section, classes.comment)}
            justify="center"
            spacing={2}
          >
            {!challengeCompleted && (
              <Grid item>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    !challengeSaved
                      ? content.save_challenge_help
                      : content.unsave_challenge_help
                  }
                >
                  <Button
                    onClick={() =>
                      isLoggedIn
                        ? challengeSaved
                          ? PostsService.instance.unsaveChallenge(post.id)
                          : PostsService.instance.saveChallenge(post.id)
                        : showLogin()
                    }
                  >
                    {challengeSaved
                      ? content.unsave_challenge
                      : content.save_challenge}
                  </Button>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <Button
                disabled={challengeCompleted}
                color="primary"
                onClick={() =>
                  isLoggedIn
                    ? showAcceptChallenge(post.rootPost ?? post.id)
                    : showLogin()
                }
              >
                {challengeCompleted
                  ? content.challenge_completed
                  : content.accept_challenge}
              </Button>
            </Grid>
            {post.cause && (
              <Grid item>
                <Button
                  className={classes.donateButton}
                  onClick={startDonation}
                >
                  {content.donate}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        {!!post.rootPost && !embedded && !disableEmbed && false && (
          <>
            <Box px={1.5} pt={1}>
              <Typography>
                <b>{content.completed_this_challenge}</b>
              </Typography>
            </Box>
            <Box p={2} py={0} px={1.5}>
              <BPostCardListItem post={{ id: post.rootPost } as any} embedded />
            </Box>
          </>
        )}
      </Card>
    );
  }
);

export default BPostCard;
